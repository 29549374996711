<template>
  <validation-observer
    ref="saveFormRef"
    #default="{invalid}"
  >
    <b-modal
      id="editPoliticAdmissionApplicantStudentModal"
      body-class="position-static"
      centered
      title="Chỉnh sửa thông tin ứng viên"
      no-close-on-backdrop
      size="lg"
      @show="onShow"
      @hide="onHide"
    >
      <b-form>
        <b-row>
          <b-col cols="6">
            <b-form-group label-for="applicantStudentName">
              <template v-slot:label>
                Họ tên <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Họ tên"
                rules="required"
              >
                <b-form-input
                  id="applicantStudentName"
                  v-model="targetItem.applicantStudentName"
                  name="applicantStudentName"
                  placeholder="Họ tên"
                  :state="getElementState(errors)"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group label-for="identification">
              <template v-slot:label>
                Số CCCD <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Số CCCD"
                rules="required"
              >
                <b-form-input
                  id="identification"
                  v-model="targetItem.identification"
                  name="identification"
                  placeholder="Số CCCD"
                  :state="getElementState(errors)"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group
              label="Ngày sinh"
              label-for="dob"
            >
              <flat-pickr
                v-model="targetItem.dob"
                class="form-control"
                :config="config"
                placeholder="Ngày sinh"
                name="dob"
              />
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group label-for="phone">
              <template v-slot:label>
                Số điện thoại <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Số điện thoại"
                rules="required"
              >
                <b-form-input
                  id="phone"
                  v-model="targetItem.phone"
                  name="phone"
                  placeholder="Số điện thoại"
                  :state="getElementState(errors)"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group
              label="Giới tính"
              label-for="genderId"
            >
              <v-select
                v-model="targetItem.genderId"
                label="name"
                :options="allGenders"
                :reduce="option => option.id"
              />
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group
              label="Dân tộc"
              label-for="ethnicId"
            >
              <v-select
                v-model="targetItem.ethnicId"
                label="name"
                :options="allFactEthnics"
                :reduce="option => option.id"
              />
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group
              label="Trình độ GDPT"
              label-for="generalEducationLevelId"
            >
              <v-select
                v-model="targetItem.generalEducationLevel"
                label="name"
                :options="allGeneralEducationLevel"
                :reduce="option => option.id"
              />
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group
              label="Trình độ CM"
              label-for="professionalQualificationId"
            >
              <v-select
                v-model="targetItem.professionalQualification"
                label="name"
                :options="allProfessionalQualification"
                :reduce="option => option.id"
              />
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group label-for="addressCurrent">
              <template v-slot:label>
                Quê quán <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Quê quán"
                rules="required"
              >
                <b-form-input
                  id="addressCurrent"
                  v-model="targetItem.addressCurrent"
                  name="addressCurrent"
                  placeholder="Quê quán"
                  :state="getElementState(errors)"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group label-for="joinPartyYear">
              <template v-slot:label>
                Năm vào Đảng
              </template>
              <b-form-input
                id="joinPartyYear"
                v-model="targetItem.joinPartyYear"
                name="joinPartyYear"
                type="number"
                placeholder="Năm vào Đảng"
              />
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group label-for="currentPosition">
              <template v-slot:label>
                Chức vụ hiện tại
              </template>
              <b-form-input
                id="currentPosition"
                v-model="targetItem.currentPosition"
                name="currentPosition"
                placeholder="Chức vụ hiện tại"
              />
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group label-for="planningPosition">
              <template v-slot:label>
                Chức vụ quy hoạch
              </template>
              <b-form-input
                id="planningPosition"
                v-model="targetItem.planningPosition"
                name="planningPosition"
                placeholder="Chức vụ quy hoạch"
              />
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group label-for="workingRank">
              <template v-slot:label>
                Ngạch bậc, vị trí việc làm
              </template>
              <b-form-input
                id="workingRank"
                v-model="targetItem.workingRank"
                name="workingRank"
                placeholder="Ngạch bậc, vị trí việc làm"
              />
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group label-for="workingUnit">
              <template v-slot:label>
                Cơ quan đơn vị công tác
              </template>
              <b-form-input
                id="workingUnit"
                v-model="targetItem.workingUnit"
                name="workingUnit"
                placeholder="Cơ quan đơn vị công tác"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label-for="workingOrganization">
              <template v-slot:label>
                Cơ quan chủ quản cơ quan, đơn vị công tác
              </template>
              <b-form-input
                id="workingOrganization"
                v-model="targetItem.workingOrganization"
                name="workingOrganization"
                placeholder="Cơ quan chủ quản cơ quan, đơn vị công tác"
              />
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
              label="Cấp cơ quan"
              label-for="agencyLevelId"
            >
              <v-select
                v-model="targetItem.agencyLevel"
                label="name"
                :options="allAgencyLevel"
                :reduce="option => option.id"
              />
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
              label="Đối tượng"
              label-for="applicantType"
            >
              <v-select
                v-model="targetItem.applicantType"
                label="name"
                :options="allApplicantType"
                :reduce="option => option.id"
              />
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
              label="Là lãnh đạo"
              label-for="isLeader"
            >
              <v-select
                v-model="targetItem.isLeader"
                label="label"
                :options="statusChecks"
                :reduce="option => option.value"
              />
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
              label="Cơ quan thuộc ngành dọc trung ương"
              label-for="isCentralLevel"
            >
              <v-select
                v-model="targetItem.isCentralLevel"
                label="label"
                :options="statusChecks"
                :reduce="option => option.value"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label-for="note">
              <template v-slot:label>
                Ghi chú
              </template>
              <b-form-textarea
                id="note"
                v-model="targetItem.note"
                name="note"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
      <template #modal-footer>
        <div class="w-100 d-flex justify-content-end">
          <b-button
            v-show="status === 1"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            :disabled="invalid"
            @click="onSave('hide')"
          >
            <span class="text-right">
              <feather-icon icon="CheckIcon" /> Cập nhật
            </span>
          </b-button>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-secondary"
            @click="$bvModal.hide('editPoliticAdmissionApplicantStudentModal')"
          >
            <span class="text-right">
              <feather-icon icon="XIcon" /> Hủy
            </span>
          </b-button>
        </div>
      </template>
      <b-overlay
        no-wrap
        variant="white"
        spinner-variant="primary"
        blur="0"
        opacity=".75"
        rounded="sm"
        :show="isLoading"
      />
    </b-modal>
  </validation-observer>
</template>

<script>
import {
  BButton,
  BCol,
  BForm,
  BFormDatepicker,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BModal,
  BOverlay,
  BRow,
  BFormTextarea,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import vSelect from 'vue-select'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import { getUser } from '@/auth/utils'
import { Flag } from '@/const/flag'
import FlatpickrLanguages from 'flatpickr/dist/l10n'
import flatPickr from 'vue-flatpickr-component'
import { POLITIC_ADMISSION_CONFIRM_CHECKS } from "@/const/status";

export default {
  name: 'Save',
  components: {
    BRow,
    // eslint-disable-next-line vue/no-unused-components
    BFormDatepicker,
    BCol,
    BFormGroup,
    BFormInput,
    BModal,
    BForm,
    BOverlay,
    // eslint-disable-next-line vue/no-unused-components
    BFormInvalidFeedback,
    BFormTextarea,
    BButton,
    flatPickr,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    item: {
      type: Object,
      default: undefined,
    },
    status: {
      type: Number,
      default: undefined,
    }
  },
  data() {
    return {
      user: getUser(),
      isLoading: false,
      targetItem: {
        applicantStudentId: null,
        applicantStudentName: null,
        identification: null,
        organizationId: getUser().orgId,
        dob: null,
        phone: null,
        genderId: null,
        ethnicId: null,
        generalEducationLevel: null,
        professionalQualification: null,
        addressCurrent: null,
        joinPartyYear: null,
        currentPosition: null,
        planningPosition: null,
        workingRank: null,
        workingUnit: null,
        workingOrganization: null,
        agencyLevel: null,
        applicantType: null,
        isLeader: 0,
        isCentralLevel: 0,
        note: null,
      },
      config: {
        enableTime: false,
        altInput: true,
        altFormat: 'd/m/Y',
        dateFormat: 'd/m/Y',
        locale: FlatpickrLanguages.vn,
      },
      required,
    }
  },
  computed: {
    ...mapGetters({
      allGenders: 'politicAdmissionConfirm/dataSources',
      allFactEthnics: 'politicAdmissionConfirm/allFactEthnics',
      allAgencyLevel: 'agencyLevel/dataSources',
      allApplicantType: 'applicantType/dataSources',
      allGeneralEducationLevel: 'generalEducationLevel/dataSources',
      allProfessionalQualification: 'professionalQualification/dataSources',
    }),
    statusOptions() {
      return this.statuses
    },
    statusChecks() {
      return POLITIC_ADMISSION_CONFIRM_CHECKS
    },
    isCreated() {
      return !this.item
    },
  },
  methods: {
    ...mapActions({
      getGenders: 'politicAdmissionConfirm/getDataSources',
      getFactEthnics: 'politicAdmissionConfirm/getFactEthnics',
      getAgencyLevel: 'agencyLevel/getDataSources',
      getApplicantType: 'applicantType/getDataSources',
      getGeneralEducationLevel: 'generalEducationLevel/getDataSources',
      getProfessionalQualification: 'professionalQualification/getDataSources',
      updateData: 'politicAdmissionConfirm/updateDataInfo',
    }),
    getElementState(errors) {
      return errors.length > 0 ? false : null
    },
    async onShow() {
      await Promise.all([
        this.getGenders({ currentPage: 1, itemsPerPage: 100 }),
        this.getFactEthnics({ currentPage: 1, itemsPerPage: 100 }),
        this.getAgencyLevel({ currentPage: 1, itemsPerPage: 100 }),
        this.getApplicantType({ currentPage: 1, itemsPerPage: 100 }),
        this.getGeneralEducationLevel({ currentPage: 1, itemsPerPage: 100 }),
        this.getProfessionalQualification({ currentPage: 1, itemsPerPage: 100 }),
      ])
      if (this.item) {
        this.targetItem = { ...this.item }
        this.targetItem.applicantType = this.allApplicantType.find(entity => entity.name === this.item.applicantType)?.id
        this.targetItem.agencyLevel = this.allAgencyLevel.find(entity => entity.name === this.item.agencyLevel)?.id
        this.targetItem.generalEducationLevel = this.allGeneralEducationLevel.find(entity => entity.name === this.item.generalEducationLevel)?.id
        this.targetItem.professionalQualification = this.allProfessionalQualification.find(entity => entity.name === this.item.professionalQualification)?.id
      } else {
        this.targetItem = { ...this.targetItem, organizationId: getUser().orgId }
      }
    },
    onHide() {
      this.$refs
        .saveFormRef
        .reset()
      this.targetItem = {
        applicantStudentId: null,
        applicantStudentName: null,
        identification: null,
        organizationId: getUser().orgId,
        dob: null,
        phone: null,
        genderId: null,
        ethnicId: null,
        generalEducationLevel: null,
        professionalQualification: null,
        addressCurrent: null,
        joinPartyYear: null,
        currentPosition: null,
        planningPosition: null,
        workingRank: null,
        workingUnit: null,
        workingOrganization: null,
        agencyLevel: null,
        applicantType: null,
        isLeader: 0,
        isCentralLevel: 0,
        note: null,
      }
    },
    async onSave(type = null) {
      const valid = this.$refs
        .saveFormRef
        .validate()
      if (valid) {
        this.isLoading = true
        try {
          const response = await this.updateData(this.targetItem)
          if (response) {
            const { isSuccessful, message } = response
            if (isSuccessful) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'CheckIcon',
                  variant: 'success',
                },
              })
              if (type === 'hide') {
                this.$bvModal.hide('editPoliticAdmissionApplicantStudentModal')
              }
              this.$emit('succeed')
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'XCircleIcon',
                  variant: 'danger',
                },
              })
            }
          }
        } catch (e) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `[${e.code}] ${e.message}`,
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        } finally {
          this.isLoading = false
        }
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
