<template>
  <validation-observer
    ref="importFormRef"
    #default="{invalid}"
  >
    <b-modal
      id="importApplicantStudentToClassModal"
      body-class="position-static"
      centered
      title="Chọn thông tin lớp cố định"
      no-close-on-backdrop
      size="lg"
      @show="onShow"
      @hide="onHide"
    >
      <b-form>
        <b-row>
          <b-col cols="12">
            <b-form-group
              label-for="trainingSystemId"
            >
              <template v-slot:label>
                Chương trình đào tạo/Bồi dưỡng <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Chương trình đào tạo/Bồi dưỡng"
                rules="required"
              >
                <v-select
                  v-model="filter.trainingSystemId"
                  label="name"
                  :options="trainingSystems"
                  :reduce="option => option.id"
                  @input="onChangeTrainingSystem"
                  placeholder="Chọn chương trình đào tạo/Bồi dưỡng"
                />
                <b-form-invalid-feedback :state="getElementState(errors)">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label-for="courseId"
            >
              <template v-slot:label>
                Khóa học <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Khóa học"
                rules="required"
              >
                <v-select
                  v-model="filter.courseId"
                  label="name"
                  :options="courses"
                  :reduce="option => option.id"
                  placeholder="Chọn khóa học"
                  @input="onChangeCourse"
                />
                <b-form-invalid-feedback :state="getElementState(errors)">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label-for="classId"
            >
              <template v-slot:label>
                Lớp học <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Lớp học"
                rules="required"
              >
                <v-select
                  v-model="filter.classId"
                  label="name"
                  :options="classes"
                  :reduce="option => option.id"
                  placeholder="Chọn lớp học"
                />
                <b-form-invalid-feedback :state="getElementState(errors)">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
      <template #modal-footer>
        <div class="w-100 d-flex justify-content-end">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            :disabled="invalid"
            @click="onSave('hide')"
          >
            <span class="text-right">
              <feather-icon icon="CheckIcon" /> Import
            </span>
          </b-button>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-secondary"
            @click="$bvModal.hide('importApplicantStudentToClassModal')"
          >
            <span class="text-right">
              <feather-icon icon="XIcon" /> Hủy
            </span>
          </b-button>
        </div>
      </template>
      <b-overlay
        no-wrap
        variant="white"
        spinner-variant="primary"
        blur="0"
        opacity=".75"
        rounded="sm"
        :show="isLoading"
      />
    </b-modal>
  </validation-observer>
</template>

<script>
import {
  BButton,
  BCol,
  BForm,
  BFormGroup,
  BFormInvalidFeedback,
  BModal,
  BOverlay,
  BRow,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import vSelect from 'vue-select'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import { getUser } from '@/auth/utils'
import { ApiCode } from "@/const/api";

export default {
  name: 'Save',
  components: {
    BRow,
    BCol,
    BFormGroup,
    BModal,
    BForm,
    BOverlay,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    selectedMainData: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      user: getUser(),
      isLoading: false,
      filter: {
        trainingSystemId: null,
        courseId: null,
        classId: null,
      },
      required,
    }
  },
  computed: {
    ...mapGetters({
      trainingSystems: 'importAcceptApplicantStudent/trainingSystems',
      courses: 'importAcceptApplicantStudent/courses',
      classes: 'importAcceptApplicantStudent/classes',
    }),
  },
  methods: {
    ...mapActions({
      getTrainingSystem: 'importAcceptApplicantStudent/getTrainingSystems',
      getCourse: 'importAcceptApplicantStudent/getCourses',
      getClasses: 'importAcceptApplicantStudent/getClasses',
      insertStudent: 'importAcceptApplicantStudent/insertStudent',
    }),
    getElementState(errors) {
      return errors.length > 0 ? false : null
    },
    async onShow() {
      await this.getTrainingSystem()
    },
    async onChangeTrainingSystem(id) {
      this.filter.courseId = null
      this.filter.classId = null
      await this.getCourse({ trainingSystemId: id })
    },
    async onChangeCourse() {
      this.filter.classId = null
      await this.getClasses({
        courseId: this.filter.courseId,
      })
    },
    onHide() {
      this.$refs
        .importFormRef
        .reset()
      this.filter = {
        trainingSystemId: null,
        courseId: null,
        classId: null,
      }
    },
    async onSave(type = null) {
      const valid = this.$refs
        .importFormRef
        .validate()
      if (valid) {
        this.isLoading = true
        try {
          const response = await this.insertStudent({
            classId: this.filter.classId,
            applicantStudentIds: this.selectedMainData,
          })
          if (response) {
            const { code, message } = response
            if (code === ApiCode.SUCCESS) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'CheckIcon',
                  variant: 'success',
                },
              })
              if (type === 'hide') {
                this.$bvModal.hide('importApplicantStudentToClassModal')
              }
              this.$emit('succeed')
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'XCircleIcon',
                  variant: 'danger',
                },
              })
            }
          }
        } catch (e) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `[${e.code}] ${e.message}`,
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        } finally {
          this.isLoading = false
        }
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
